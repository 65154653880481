<template>
  <div class="adminpanel">
    <div class="menuContainer">
      <component :is="menuModal" ></component>
    </div>
    <Nav @newMenu="changeMenu" />
  </div>
</template>

<script>
import Nav from "../components/Navigation";
import { defineAsyncComponent } from 'vue'
// import UserManagement from "../components/userManagement/UserManagement.vue";
// import ProjectManagement from "../components/projectManagement/ProjectManagement.vue";
// import StreamRoomManagement from "../components/streamRoomManagement/StreamRoomManagement.vue"

const UserManagement = defineAsyncComponent(() =>
  import('../components/userManagement/UserManagement.vue')
)
const ProjectManagement = defineAsyncComponent(() =>
  import('../components/projectManagement/ProjectManagement.vue')
)
const StreamRoomManagement = defineAsyncComponent(() =>
  import('../components/streamRoomManagement/StreamRoomManagement.vue')
)
const EditUser = defineAsyncComponent(() =>
  import('../components/EditUser.vue')
)

const ActivityTracking = defineAsyncComponent(() =>
  import('../components/activityTracking/ActivityTracking.vue')
)




export default {
  name: "AdminPanel",
  components: {
    Nav,
    UserManagement,
    ProjectManagement,
    StreamRoomManagement,
    EditUser,
    ActivityTracking
  },
  data() {
    return {
      menuModal: "StreamRoomManagement",
    };
  },
  created() {
    this.$store.state.currentLocation = "Admin Panel"
    this.$store.state.currentLocationId = "N/A"
  },
  methods: {
    changeMenu(value) {
      this.menuModal = value;
      console.log(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.adminpanel {
  display: flex;
  justify-items: center;
  align-items: center;
  background-image: url("../assets/loginbackground.jpg");
  max-width: 100vw;
  height: 100%;
}

.menuContainer {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>