<template>
  <div class="nav">
    <div class="nav-header">
      <font-awesome-icon
        v-if="
          this.$store.state.userClaims.admin ||
          this.$store.state.userClaims.broadcaster
        "
        size="2x"
        :icon="['fas', 'cogs']"
      />
      <font-awesome-icon
        v-if="this.$store.state.userClaims.viewer"
        size="2x"
        :icon="['fas', 'tv']"
      />
      <h1>{{ navTitle }}</h1>
    </div>

    <div class="nav-wrapper">
      <div class="nav-category">
        <div class="nav-cat-header">
          <font-awesome-icon :icon="['fas', 'th']" />
          <h4>Overview</h4>
        </div>
        <ul>
          <li
            id="StreamRoomManagement"
            @click="select($event)"
            :class="{ selected: this.targetId == 'StreamRoomManagement' }"
          >
            Active Stream Rooms
          </li>
        </ul>
      </div>

      <div
        v-if="
          this.$store.state.userClaims.admin ||
          this.$store.state.userClaims.broadcaster
        "
        class="nav-category"
      >
        <div class="nav-cat-header">
          <font-awesome-icon :icon="['fas', 'key']" />
          <h4>Manage System</h4>
        </div>
        <ul>
          <li
            id="ProjectManagement"
            @click="select($event)"
            :class="{ selected: this.targetId == 'ProjectManagement' }"
          >
            Projects
          </li>
          <li
            id="UserManagement"
            @click="select($event)"
            :class="{ selected: this.targetId == 'UserManagement' }"
          >
            Users
          </li>
          <!-- <li>Services</li> -->
          <li
            id="ActivityTracking"
            @click="select($event)"
            :class="{ selected: this.targetId == 'ActivityTracking' }"
          >
            Activity Tracking
          </li>
        </ul>
      </div>

      <div id="YourSettings" class="nav-category">
        <div class="nav-cat-header">
          <font-awesome-icon :icon="['fas', 'key']" />
          <h4>Your Settings</h4>
        </div>
        <ul>
          <li
            id="EditUser"
            @click="select($event)"
            :class="{ selected: this.targetId == 'EditUser' }"
          >
            User Profile
          </li>
        </ul>
      </div>
    </div>
    <div class="navfooter">
      <div class="name-container">
        <p>logged in as</p>
        <p>{{ this.$store.state.profileFirstName }}</p>
      </div>
      <button class="logout" @click.prevent="logOut">Log Out</button>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  timestamp,
  auth,
  rtdb,
  db,
} from "../components/firebase/firebaseInit.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);

export default {
  name: "Navigation",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      navcomp: "ManageStreamsNav",
      navmode: "",
      targetId: "StreamRoomManagement",
    };
  },
  computed: {
    navTitle() {
      if (this.$store.state.userClaims.viewer) {
        return "Stream Browser";
      } else if (this.$store.state.userClaims.admin) {
        return "Administration";
      } else {
        return "Unknown";
      }
    },
  },
  methods: {
    select(event) {
      this.targetId = event.currentTarget.id;
      this.$emit("newMenu", this.targetId);
      //console.log(targetId); // returns 'foo'
    },
    logOut() {
      this.$store.state.triggerLogout = true;
      // let rtTracking = rtdb.ref(
      //   "realtimeTracking/" + this.$store.state.profileId
      // );

      // rtTracking.update({
      //   lastDisconnectTime: firebase.database.ServerValue.TIMESTAMP,
      //   active: false,
      //   user: this.$store.state.profileEmail,
      // });


      // let logAccess = rtdb.ref("masterLog/").push();

      // let currentStreamRoom = null; 
      // let currentStreamRoomId = null;

      // console.log(this.$store.state.streamRoom == "Stream Browser" || this.$store.state.streamRoom == "Admin Panel")
      // console.log(this.$store.state.streamRoom)

      // if (this.$store.state.streamRoom == "Stream Browser" || this.$store.state.streamRoom == "Admin Panel") {
      //   currentStreamRoom = "N/A";
      //   currentStreamRoomId = "N/A";
      // } else {
      //   currentStreamRoom = this.$store.state.currentLocation;
      //   currentStreamRoomId = this.$store.state.currentLocationId;
      // }

      // logAccess.set({
      //   type: "logout",
      //   user: user.email,
      //   userId: user.uid,
      //   ipAddress: this.$store.state.currentIpAddress,
      //   deviceId: this.$store.state.deviceId,
      //   currentDevice: this.currentDevice,
      //   geoLocation: this.geoLocation,
      //   streamRoomName: currentStreamRoom,
      //   streamRoom: currentStreamRoomId,
      //   sendDate: firebase.database.ServerValue.TIMESTAMP,
      // });

      // firebase
      //   .auth()
      //   .signOut()
      //   .then(() => {
      //     firebase.auth().onAuthStateChanged((userAuth) => {
      //       if (!userAuth) {
      //         this.$store.dispatch("logOut");
      //         this.$router.replace("/");
      //       }
      //     });
      //   });
    },
  },
  created() {
    if (this.$store.state.userClaims.viewer) {
      this.navmode = "viewer";
    }
  },
};
</script>

<style lang="scss" >
.nav {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #2f323c;
  z-index: 1001;
  min-width: 312px;
  margin-left: auto;
  min-height: 100vh;

  .nav-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      margin-left: 10px;
    }
  }

  #yourSettings {
    bottom: 0px;
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 15px;
  }
  .nav-category {
    border: #17181a 1px solid;
    background-color: #262830;
    width: 85%;
    margin-top: 15px;

    .nav-cat-header {
      background-color: #1c1d23;
      padding: 10px;
      font-size: 13px;
      display: flex;
      align-items: center;

      h4 {
        font-size: 13px;
        margin: 0px 10px;
      }
    }

    ul {
      margin: 0px;
      padding-left: 15px;
    }
    li {
      font-weight: 700;
      font-size: 13px;
      list-style-type: none;
      padding: 10px 0px;
    }

    li:hover {
      color: RGB(0, 132, 255);
      cursor: pointer;
    }

    li.selected {
      color: RGB(0, 132, 255);
    }
  }

  .nav-wrapper {
    display: flex;
    width: 100%;
    text-align: start;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    flex-shrink: 0;
    flex-grow: 1;
  }

  button {
    align-self: center;
  }

  .logout {
    background-color: #2f323c;
    border: 1px solid #9296a1;
    height: 38px;
    width: 110px;
    color: #e1ecff;
    font-weight: 400;
    font-size: 1em;
    margin-right: 5px;
    padding: 0px;
  }

  .logout:hover {
    background-color: #262830;
  }
}

.navfooter {
  width: 100%;
  height: 50px;
  background: #262830;
  margin-top: auto;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  .name-container {
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 5px;
  }
  p {
    font-size: 0.9em;
    margin-block: 0;
  }
  p:last-of-type {
    font-size: 1em;
  }
}
</style>